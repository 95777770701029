import dayjs from "dayjs";

import CryptoJS from "crypto-js";
const _keypub = "%h:654ewgrh5:63-67Mf223d257*2";

export default {
  _createPubToken() {
    let result = "";
    try {
      const data = {
        iat: dayjs().format(), 
        exp: dayjs().add(1, "minute").format(), 
      };

      const str = JSON.stringify(data);
      result = CryptoJS.AES.encrypt(str, _keypub).toString();
    } catch (err) {
      
    }

    return result;
  },

  _decryptPubData(data) {
    let result = "";
    try {
      var bytes = CryptoJS.AES.decrypt(data, _keypub);
      result = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (err) {
      
    }

    return result;
  },

  _encryptPubData(data) {
    let result = "";
    try {
      const str = JSON.stringify(data);
      result = CryptoJS.AES.encrypt(str, _keypub).toString();
    } catch (err) {
      
    }

    return result;
  },
};
