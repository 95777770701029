<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "App",
  data() {
    return {
      colores: "",
    };
  },

  async created() {
    await this.getAllInfoClr("profile");

    this.colores = this.profiles;
    this.actualizarColores();
  },
  watch: {
    profiles() {
      this.actualizarColores();
    },
  },
  computed: {
    profiles() {
      return this.$store.getters["profile/data"];
    },
  },
  methods: {
    ...mapActions("colors", ["getAllInfoClr"]),
    ...mapActions("profile", ["getAllInfoPrf"]),
    actualizarColores() {
      const arr = this.$store.getters["colors/data"];

      if (Array.isArray(arr)) {
        for (var i = 0; i < arr.length; i++) {
          if (i == 0) {
            document.documentElement.style.setProperty(
              "--color-1",
              arr[i].code
            );
          } else if (i == 1) {
            document.documentElement.style.setProperty(
              "--color-2",
              arr[i].code
            );
          } else if (i == 2) {
            document.documentElement.style.setProperty(
              "--color-3",
              arr[i].code
            );
          } else if (i == 3) {
            document.documentElement.style.setProperty(
              "--color-4",
              arr[i].code
            );
          } else if (i == 4) {
            document.documentElement.style.setProperty(
              "--color-5",
              arr[i].code
            );
          } else if (i == 5) {
            document.documentElement.style.setProperty(
              "--color-6",
              arr[i].code
            );
          } else if (i == 6) {
            document.documentElement.style.setProperty(
              "--color-7",
              arr[i].code
            );
          }
        }
      }
    },
  },
};
</script>

<style>
@import "./css/default.css";

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&family=Inter:wght@100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Sulphur+Point:wght@300;400;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Sulphur+Point:wght@300;400;700&display=swap');

html {
  scroll-behavior: smooth;
}

:root {
  /** No eliminar los 7 colores */
  --color-1: #0055b8;
  --color-2: #ffc844;
  --color-3: #ffffff;
  --color-4: #f5f5f5;
  --color-5: #a7a7a7;
  --color-6: #d9d9d9;
  --color-7: #fff;
  --color-8: #92c0e9;
  --color-9: #3c3c3c;
  --color-10: #d4f0ff;
  --color-11: #edd8ff;
  --color-12: #fffad0;
  --color-13: #d9ffcc;
  --color-14: #f93549;
  --color-15: #b5ff9b;
  --color-16: #6cb6e0;
  --color-17: #fff496;
  --color-18: #1E3D85;
  --color-19: #4CC8D9;
  --color-20: #FFAA47;
  overscroll-behavior: none;


  --opacity: rgba(255, 255, 255, 0.6);


}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0vw;
}

body {
  margin: 0vw;
}

.dflxc {
  display: flex;
  justify-content: center;
}

html {
  scroll-behavior: smooth;
}

input:focus-visible {
  outline: none !important;
}

input,
textarea,
select {
  padding-left: 0.5vw;
  outline: none;
}

.dflx {
  display: flex;
}

.dflxa {
  display: flex;
  align-items: center;
}

.dflxca {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dflxsp {
  display: flex;
  justify-content: space-between;
}

.porel {
  position: relative;
}

.pointer {
  cursor: pointer;
}

.jcsb {
  justify-content: space-between;
}

.box_ctr {
  display: flex;
  justify-content: center;
}

.box_ctr_alg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.crs_ptr {
  cursor: pointer;
}

body::-webkit-scrollbar {
  -webkit-appearance: none;
}

body::-webkit-scrollbar:vertical {
  width: 0.3645833333333333vw;
  background-color: #F4F4F4;
  /** border-radius: 1.0416666666666665VW;  */
}

body::-webkit-scrollbar-button:increment,
body::-webkit-scrollbar-button {
  display: none;
}

body::-webkit-scrollbar:horizontal {
  height: 0.3645833333333333vw;
}

body::-webkit-scrollbar-thumb {
  background-color: #E9B26F;
  /** border-radius: 1.0416666666666665VW;  */
  border: 0.10416666666666667vw solid #E9B26F;
}

body::-webkit-scrollbar-track {
  border-radius: 0.3645833333333333vw;
}

.carg_btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: .7vw;


}

.carg_btns p:first-child {
  margin-right: 1vw;
}

.carg_btns p {
  background-color: #24397E;
  border-radius: 1.5vw;
  height: 2vw;
  margin: 0vw;
  letter-spacing: 3px;
  font-family: var(--DMSans);
  font-size: .7vw;
  width: 6vw;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.carg_btns p img {
  width: 2.5vw;
}

.cargando_pl {
  width: 14vw !important;
  color: white !important;
}

.div_status {
  display: flex;
  align-items: center;
}

.div_status p {
  margin: 0vw;
  background-color: #d9d9d9;
  color: white;
  height: 2.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: .5vw;
  width: 7.7vw;
  cursor: pointer;
  transition: all 300ms;
}

.div_status p:hover {
  background-color: #24397E;
}

.div_status p.ds_act {
  background-color: #24397E;
  color: white;
}

.list_group {
  display: flex;
  align-items: center;
}

.list_cont {
  width: 16vw;
  margin-right: 2.5vw;
}

.lc_item_sel {
  width: 14.167vw;
  display: flex;
  border: 1px solid #DBDBDB;
  padding: 0.417vw 0.573vw 0.469vw 0.677vw;
  cursor: pointer;
  margin-bottom: 0.156vw;
}

.lc_item_sel p {
  width: 13.542vw;
  margin: 0vw;
  color: #8D8D8D;

  font-size: 0.521vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  height: 0.677vw;
}

.lc_item_sel img {
  width: 0.625vw;
}

.list_body {
  background-color: #fff;
  height: 5vw;
  overflow: auto;
}

.list_body::-webkit-scrollbar {
  -webkit-appearance: none;
}

.list_body::-webkit-scrollbar:vertical {
  width: 0.3645833333333333vw;
  background-color: #F4F4F4;
  /** border-radius: 1.0416666666666665VW;  */
}

.list_body::-webkit-scrollbar-button:increment,
.list_body::-webkit-scrollbar-button {
  display: none;
}

.list_body::-webkit-scrollbar:horizontal {
  height: 0.3645833333333333vw;
}

.list_body::-webkit-scrollbar-thumb {
  background-color: #07264a;
  /** border-radius: 1.0416666666666665VW;  */
  border: 0.10416666666666667vw solid #07264a;
}

.list_body::-webkit-scrollbar-track {
  border-radius: 0.3645833333333333vw;
}

.lb_row {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 14.167vw;
  border-bottom: 1px solid #DBDBDB;
  padding: 0.417vw 0.573vw 0.469vw 0.677vw;
}

.lb_row p {
  display: flex;
  align-items: center;
  margin: 0vw;
  width: 13.542vw;
  color: #8D8D8D;

  font-size: 0.521vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

p.itlc {
  font-weight: 500;
}

.csp {
  cursor: pointer !important;
}

.wrapper .descripcion * {
  color: inherit !important;
  font-family: inherit !important;
  margin: 0 !important;
  font-size: inherit !important;
  line-height: inherit !important;
  letter-spacing: inherit !important;
}

.equipo__bio div:last-of-type>* {
  margin: 0;
  padding: 0;
}

.slide__contenido>* {
  margin: 0;
  padding: 0;
}

.slide__contenido strong {
  font-family: MartianGroteskW05-StdBl !important;
  font-weight: 900 !important;
}

/* OurClients.vue */

.logos__individual div>* {
  margin: 0;
}

/*Banner.vue */

.text__wrapper>div * {
  margin: 0;
}

/*Prepage.vue */

.content>div>* {
  margin: 0;
}
</style>
